import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import { slideInAnimation } from '../../animations/slide-in';
import { UserPreferenceService } from '../../services/user-preference.service';
import { AuthenticatedUserService } from '../../services/authenticated-user.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AuthenticatedUserModel } from '../../models/authenticated-user';
import { PageLoadingService } from '../../services/page-loading.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RouterOutlet,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    slideInAnimation
  ]
})

export class LayoutComponent implements OnInit, OnDestroy {
  initComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userIsSignedIn$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  authenticatedUser$: BehaviorSubject<AuthenticatedUserModel | null> = new BehaviorSubject<AuthenticatedUserModel | null>(null);
  railMode = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(private readonly contexts: ChildrenOutletContexts,
    public readonly userPreferenceService: UserPreferenceService,
    private readonly authenticatedUserService: AuthenticatedUserService,
    public pageLoadingService: PageLoadingService) {
  }


  ngOnInit(): void {
    this.authenticatedUserService.authenticatedUser$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((authenticatedUser) => {
        this.userIsSignedIn$.next(!!authenticatedUser);
        this.authenticatedUser$.next(authenticatedUser);
      });

    this.userPreferenceService.darkMode$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((darkMode: boolean) => {
        this.setDarkModeSelection(darkMode);
      });

    this.userPreferenceService.railMode$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((railMode: boolean) => {
        this.railMode = railMode;
      });    

    setTimeout(() => {
      this.initComplete$.next(true);
    });
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout(): void {
    this.authenticatedUserService.logout();
  }

  getRouteAnimationData(): string {    
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  private setDarkModeSelection(darkMode: boolean) {
    const darkClassName = 'dark-mode';
    if (darkMode) {
      document.body.classList.add(darkClassName);
    } else {
      document.body.classList.remove(darkClassName);
    }
  }
}
