import { Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import { AuthenticatedUserModel } from '../models/authenticated-user';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  AuthError,
  EventMessage,
  EventType,
  InteractionStatus
} from '@azure/msal-browser';

import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { ReadUserModel } from '../models/read-user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthenticatedUserService {
  public readonly authenticatedUser$: BehaviorSubject<AuthenticatedUserModel | null> = new BehaviorSubject<AuthenticatedUserModel | null>(null);
  public readonly loggedInRxDataNetworkUser$: BehaviorSubject<ReadUserModel | null> = new BehaviorSubject<ReadUserModel | null>(null);

  constructor(
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly userService: UserService,
    private readonly router: Router) {

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        this.updateAuthenticatedUser();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        if (environment.authentication.authorityDomain.indexOf(payload.tenantId) > -1) {
          this.msalService.instance.setActiveAccount(payload.account);
        }
        //can't seem to avoid
        //ERROR Error: Uncaught(in promise): BrowserAuthError: interaction_in_progress: Interaction is currently in progress.Please ensure that this interaction has been completed before calling an interactive API.For more visit: aka.ms / msaljs / browser - errors.
        if (result.eventType === 'msal:loginSuccess' && result.interactionType === 'redirect') {
          //location.reload();
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE)
      )
      .subscribe((result: EventMessage) => {
        debugger;
        if (result.error instanceof AuthError) {
          if (result.error.message.indexOf('The user has forgotten their password') > -1) {
            this.router.navigate(['/forgot-password']);
          }
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_START)
      )
      .subscribe(() => {
        this.invalidateUserProfileData();
      });

    this.authenticatedUser$
      .subscribe((authenticatedUser) => {
        this.updateLoggedInRxDataNetworkUser(authenticatedUser);
      });
  }

  logout() {
    const activeAccount = this.msalService.instance.getActiveAccount() || this.msalService.instance.getAllAccounts()[0];

    if (activeAccount) {
      this.msalService.logoutRedirect({ account: activeAccount });
    }
  }

  private invalidateUserProfileData(): void {
    this.authenticatedUser$.next(null);
  }

  private updateAuthenticatedUser() {
    const authenticatedUser = this.getAuthenticatedUser();
    this.authenticatedUser$.next(authenticatedUser);
    if (!authenticatedUser) {
      this.invalidateUserProfileData();
    }
  }

  private updateLoggedInRxDataNetworkUser(authenticatedUser: AuthenticatedUserModel | null) {
    if (!authenticatedUser) {
      this.loggedInRxDataNetworkUser$.next(null);
    } else {
      //debugger;
      this.userService.getByAuthenticatedUser()
        .subscribe((getByAuthenticatedUserResponse) => {
          this.loggedInRxDataNetworkUser$.next(getByAuthenticatedUserResponse.user);
        });
    }
  }

  private getAuthenticatedUser(): AuthenticatedUserModel | null {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount) {
      const accounts = this.msalService.instance.getAllAccounts();
      if (accounts.length > 0) {
        this.msalService.instance.setActiveAccount(accounts[0]);
        activeAccount = this.msalService.instance.getActiveAccount();
      }
    }

    if (activeAccount) {
      return { email: activeAccount.username, name: activeAccount.name ?? '' };
    }

    return null;
  }
}
