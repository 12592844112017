import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReadGetByAuthenticatedUserResponse } from '../models/api-call-response/read-get-by-authenticated-user-response';
import { ReadSearchForUserByEmailResponse } from '../models/api-call-response/read-search-for-user-by-email-response';
import { ReadSendEmailConfirmationCodeResponse } from '../models/api-call-response/read-send-email-confirmation-code-response';
import { ReadUserChangePasswordResponse } from '../models/api-call-response/read-user-change-password-response';
import { ReadUserRegistrationResponse } from '../models/api-call-response/read-user-registration-response';
import { WriteUserPasswordChange } from '../models/write-user-password-change';
import { WriteUserRegistration } from '../models/write-user-registration';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly http: HttpClient) {
  }

  public getByAuthenticatedUser(): Observable<ReadGetByAuthenticatedUserResponse> {
    return this.http.get<ReadGetByAuthenticatedUserResponse>(`${environment.rxDataNetworkApiUrl}/v1/user/get-by-authenticated-user`);
  } 

  public searchForUserByEmail(email: string): Observable<ReadSearchForUserByEmailResponse> {
    return this.http.get<ReadSearchForUserByEmailResponse>(`${environment.rxDataNetworkApiUrl}/v1/user/search-for-user-by-email/${email}`);
  }

  public sendEmailConfirmationCode(email: string, isPasswordReset: boolean): Observable<ReadSendEmailConfirmationCodeResponse> {
    return this.http.get<ReadSendEmailConfirmationCodeResponse>(`${environment.rxDataNetworkApiUrl}/v1/user/send-email-confirmation-code/${email}?isPasswordReset=${isPasswordReset}`);
  }

  public registerUser(model: WriteUserRegistration): Observable<ReadUserRegistrationResponse> {
    return this.http.post<ReadUserRegistrationResponse>(`${environment.rxDataNetworkApiUrl}/v1/user/register-user`, model);
  }

  public resetPassword(model: WriteUserPasswordChange): Observable<ReadUserChangePasswordResponse> {
    return this.http.post<ReadUserChangePasswordResponse>(`${environment.rxDataNetworkApiUrl}/v1/user/change-user-password`, model);
  }

  public userExistsByEmail(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.rxDataNetworkApiUrl}/v1/user/user-exists-by-email/${email}`);
  }
}
