import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {
  public readonly darkMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly railMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.darkMode$.next(this.getBooleanUserPrefByName(this.darkModeName, false));
    this.railMode$.next(this.getBooleanUserPrefByName(this.railModeName, false));
  }

  private railModeName = 'railMode';
  private darkModeName = 'darkMode';

  public toggleUserIsDarkMode(): void {
    const isInDarkMode = this.getBooleanUserPrefByName(this.darkModeName, true);
    this.setBooleanUserPrefByName(this.darkModeName, !isInDarkMode);
    this.darkMode$.next(!isInDarkMode);
  }

  public toggleUserIsRailMode(): void {
    const isInRailMode = this.getBooleanUserPrefByName(this.railModeName, true);
    this.setBooleanUserPrefByName(this.railModeName, !isInRailMode);
    this.railMode$.next(!isInRailMode);
  }

  public getJsonUserPrefByName(name: string): JSON | undefined {
    const localStorageValue = localStorage.getItem(name);
    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    }
    return undefined;
  }

  public setJsonUserPrefByName(name: string, value: JSON) {
    localStorage.setItem(name, JSON.stringify(value));
  }

  public getBooleanUserPrefByName(name: string, defaultReturn: boolean): boolean {
    const localStorageValue = localStorage.getItem(name);
    if (localStorageValue) {
      return localStorageValue.toLowerCase() === 'true';
    }
    return defaultReturn;
  }

  public setBooleanUserPrefByName(name: string, value: boolean) {
    localStorage.setItem(name, value.toString());
  }

  public getNumberUserPrefByName(name: string, defaultReturn: number): number {
    const localStorageValue = localStorage.getItem(name);
    if (!!localStorageValue && !isNaN(parseFloat(localStorageValue))) {
      return +localStorageValue;
    }
    return defaultReturn;
  }

  public setNumberUserPrefByName(name: string, value: number) {
    localStorage.setItem(name, value.toString());
  }
}
