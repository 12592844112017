export const environment = {
  production: false,
  rxDataNetworkApiUrl: 'https://dev-rx-data-network-api.azurewebsites.net',
  blobUrl: 'https://rxdatanetworkstorage.blob.core.windows.net/drug-images/',
  authentication: {
    clientId: 'e3a8725f-6aef-44c1-8256-d5a42cc4837d',
    b2cPoliciesSignInAuthority: 'https://devB2CRXDN.b2clogin.com/devB2CRXDN.onmicrosoft.com/B2C_1_SignIn',
    scopesEndpoint: 'https://devB2CRXDN.onmicrosoft.com/6fcb3f14-bc6d-427b-b87d-9a58d00cc89c/RxDataNetworkAPI.Access',
    authorityDomain: 'devB2CRXDN.b2clogin.com',
    redirectUri: 'http://localhost:4201/'
  }
};
