import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

const routes: Routes = [  
  {
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: 'create-account',
    loadComponent: () => import('./components/create-account/create-account.component').then(mod => mod.CreateAccountComponent),
    data: { animation: 'CreateAccount' }
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./components/forgot-password/forgot-password.component').then(mod => mod.ForgotPasswordComponent),
    data: { animation: 'ForgotPassword' }
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./components/dashboard/dashboard.component').then(mod => mod.DashboardComponent),
    canActivate: [MsalGuard],
    data: { animation: 'Dashboard' }
  },
  {
    path: '',
    loadComponent: () => import('./components/home/home.component').then(mod => mod.HomeComponent),
    data: { animation: 'Home' }
  },
  {
    path: '**',
    redirectTo: '/'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
