import { environment } from 'src/environments/environment';
/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation, IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const b2cPolicies = {
  names: {
    signIn: 'B2C_1_SignIn'
  },
  authorities: {    
    signIn: {
      authority: environment.authentication.b2cPoliciesSignInAuthority
    }
  },
  authorityDomain: environment.authentication.authorityDomain
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.authentication.clientId, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/', 
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Warning,
      piiLoggingEnabled: false
    }
  }
}

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedAPIResourceMap: Map<string, Array<string | ProtectedResourceScopes> | null> = new Map<string, Array<string | ProtectedResourceScopes> | null>([
  [`${environment.rxDataNetworkApiUrl}/v1/user/user-exists-by-email/*`, null],
  [`${environment.rxDataNetworkApiUrl}/v1/user/send-email-confirmation-code/*`, null],
  [`${environment.rxDataNetworkApiUrl}/v1/user/register-user*`, null],
  [`${environment.rxDataNetworkApiUrl}/v1/user/change-user-password*`, null],
  [environment.rxDataNetworkApiUrl, [environment.authentication.scopesEndpoint]]
]);


export const loginScopes = {
  scopes: [environment.authentication.scopesEndpoint]
}


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: protectedAPIResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginScopes
  };
}
