<!--sign in info is null-->
<div *ngIf="(initComplete$ | async) === false" class="mt-5 pt-5 text-center">
  <div class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
  <h4 class="mt-4">Checking authentication status...</h4>
</div>

<!--user is signed in-->
<ng-template [ngIf]="(initComplete$ | async) === true">
  <div class="d-flex flex-column min-vh-100">
    <div class="d-flex align-items-center mx-2 mx-md-3" style="min-height: 50px;">
      <img class="invert-on-dark me-2" src="/assets/icons/icon.svg" id="rx-data-network-logo-logged-in" />
      <h5 class="m-0 me-3">
        Data Network
      </h5>

      <button *ngIf="(userIsSignedIn$ | async) === true" mat-icon-button color="primary" aria-label="Menu" (click)="userPreferenceService.toggleUserIsRailMode()">
        <mat-icon>menu</mat-icon>
      </button>

      <div class="flex-grow-1"></div>
      <div class="me-2">
        <input type="checkbox" id="toggleDarkModeControl" [class.checked]="(userPreferenceService.darkMode$ | async)">
        <label for="toggleDarkModeControl" id="dark-toggle-label" (click)="userPreferenceService.toggleUserIsDarkMode()">
          <mat-icon id="dark-mode-icon">dark_mode</mat-icon>
          <mat-icon id="light-mode-icon">light_mode</mat-icon>
          <span id='dark-toggle-ball'></span>
        </label>
      </div>
    </div>

    <mat-progress-bar mode="determinate" *ngIf="(pageLoadingService.pageIsLoading$ | async) === false" value="0"></mat-progress-bar>
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="pageLoadingService.pageIsLoading$ | async"></mat-progress-bar>

    <div class="d-flex flex-grow-1">

      <!--for now just going to maintain mobile and desktop navs kind of seperately, hard to manage-->
      <div class="d-md-none mat-elevation-z8">
        MOBILE
      </div>

      <div class="d-none d-md-block">
        <div id="side-bar" *ngIf="(userIsSignedIn$ | async) === true" class="d-flex flex-column flex-shrink-0" [ngClass]="railMode ? 'rail-width' : 'non-rail-width'" style="min-height: calc(100vh - 55px)">
          <ul class="side-bar-nav flex-column mb-auto" [ngClass]="railMode ? 'text-center' : 'text-left'">
            <li class="side-bar-nav-item">
              <a routerLink="dashboard"
                 routerLinkActive="active-link"
                 matTooltip="Dashboard"
                 matTooltipPosition="right"
                 aria-label="Dashboard">
                <mat-icon>dashboard</mat-icon>
                <span *ngIf="!railMode" class="ms-3">Dashboard</span>
              </a>
            </li>
          </ul>
          <div class="flex-grow-1"></div>
          <ul class="side-bar-nav flex-column mb-4" [ngClass]="railMode ? 'text-center' : 'text-left'">
            <li class="side-bar-nav-item">
              <a routerLink="my-account"
                 routerLinkActive="active-link"
                 matTooltip="My Account -  {{(authenticatedUser$ | async)?.name}}"
                 matTooltipPosition="right"
                 aria-label="My Account">
                <mat-icon>account_circle</mat-icon>
                <span *ngIf="!railMode" class="ms-3">My Account</span>
              </a>
            </li>
            <li class="side-bar-nav-item">
              <a (click)="logout()"
                 matTooltip="Logout {{(authenticatedUser$ | async)?.name}}"
                 matTooltipPosition="right"
                 class="clickable">
                <mat-icon>logout</mat-icon>
                <span *ngIf="!railMode" class="ms-3">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex-grow-1 my-2" [@routeAnimation]="getRouteAnimationData()">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-template>
